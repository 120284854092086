<template>
    <ValidationObserver ref="validator" slim>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-toolbar flat>
                <v-toolbar-title>Вопрос</v-toolbar-title>
                <v-spacer/>
                <v-switch
                    v-model="entity.enabled"
                    label="Активен"
                    hide-details
                />
            </v-toolbar>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.question"
                    label="Вопрос"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.answer"
                    label="Ответ"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>

            <v-row
                align="center"
                justify="space-between"
                class="mt-4 mx-0">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save">Сохранить</v-btn>
                <v-btn
                    v-if="entity._id"
                    color="error"
                    @click="remove">Удалить</v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { imageSrc } from '../../helpers';

    export default {
        name: 'QuesyionsEdit',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            imageSrc,
            valid: true,
        }),
        computed: {
            ...mapState('questions', ['entity']),
        },
        async mounted() {
            if(this.$route.params.id) {
                await store.dispatch('questions/get', { id: this.$route.params.id });
            } else {
                await store.commit('questions/CLEAR_ENTITY');
            }
        },
        methods: {
            async setPicture(file) {
                this.entity.picture = await store.dispatch('files/upload', { file });
            },
            async save() {
                this.valid = await this.$refs.validator.validate();
                if(this.valid){
                    await store.commit('questions/SET_ENTITY', this.entity);
                    const response = await store.dispatch('questions/save');
                    if(response.error) {
                        alert(response.error);
                    } else {
                        await this.$router.push({ name: 'questions-list' });
                    }
                }
            },
            async remove() {
                await store.dispatch('questions/delete', { id: this.entity._id });
                await this.$router.push({ name: 'questions-list' });
            }
        }
    }
</script>

<style lang="scss">
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
    .questions__content__block {
        margin: 10px 0;
        padding: 20px;
        border: 1px solid #cecece;
        border-radius: 4px;
    }
</style>
